<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import simplebar from "simplebar-vue";
import Common from "@/components/common";
import appConfig from "@/app.config";
import { EncryptStorage } from 'encrypt-storage';
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      modules:[],
      menuItems: [
        {
          id: 0,
          label: "Menu", //menuitems.menu.text"
          isTitle: true,
          module: "base",
        },
        {
          id: 1,
          module: "dashboard",
          label: "Dashboard",
          icon: "uil-home-alt",
          // badge: {
          //     variant: "primary",
          //     text: "2"
          // },
          link: "/",
        },
      ],
      loading: true,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  components: {
    simplebar,
    Common
  },
  mounted: async function () {
    var menuRef = new MetisMenu("#side-menu");
    // eslint-disable-next-line no-unused-vars
    // this.accessToken = this.$refs.commonFunc.getToken()
    // this.accessPhone = this.$refs.commonFunc.getUsername()
   
    const data =  await this.$refs.commonFunc.getFullData()
    console.log(data)
  
    if (data.account_type == "admin"){
      this.menuItems[0].label = "Admin"
    }
    else{
      this.menuItems[0].label = "Staff"
    }
    setTimeout(() => {
      this._activateMenuDropdown();
    }, 0);
    
    this.$router.afterEach(() => {
      this._activateMenuDropdown();
    });
    this.loading=false;
    console.log(menuRef)
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              break;
            case "light":
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
    $route: {
      handler: "onRoutechange",
      immediate: false,
      deep: true,
    },
  },
  created(){
    console.log("sssssss")
    this.loading=true
    const modules = encryptStorage.getItem('mds')
    this.modules = modules
    console.log(modules)
    this.addMenu();
  },
  methods: {
    addMenu(){
      var ModuleName = this.modules.map((el) => el.name);
      console.log("ModuleName -> "+ ModuleName)
      var ChangeOrderList = []
      // Your desired order
      // const desiredOrder = [
      //     "applicant",
      //     "factoring_application",
      //     "letter_of_support",
      //     "term_sheet",
      //     "asset_quality",
      //     "legal_agreements",
      //     "pre_disbursement",
      //     "credit_review",
      //     "asset_quality_reports",
      //     "disbursement",
      //     "refund",
      //     "settlement",
      //     "statement_of_account",
      //     "notice_of_outstanding",
      //     "operations_reports",
      //     "reports",
      //     "user_management",
      //     "approving_authority"
      // ];

      const desiredOrder = [
          "applicant",
          "factoring_application",
          "letter_of_support",
          "term_sheet",
          "user_management",
          "approving_authority"
      ];


      // Process the array elements in the desired order
      desiredOrder.forEach(element => {
          if (ModuleName.includes(element)) {
              // Process the element here
              ChangeOrderList.push(element)
          }
      });

      ChangeOrderList.forEach((value) => {
        if (value=='applicant' || value=='factoring_application' || value=='letter_of_support' || value=='term_sheet'){
          const alreadyInsideArray = this.menuItems.find(e => e.module === 'business');
          if (alreadyInsideArray==undefined){
            this.menuItems.push(
            {
              id: 2,
              label: "Business",
              module: "business",
              icon: "uil uil-briefcase-alt",
              subItems: []
            });
          }
          const detectModule = this.menuItems.find(e => e.module === 'business');
          if (detectModule) {
              if (value=='applicant'){
                detectModule.subItems.push({
                    id: 201,
                    module: "applicant",
                    label: "Applicants",
                    icon: "",
                    link: "/applicants"
                });
              }
          }
          if (detectModule) {
              if (value=='factoring_application'){
                detectModule.subItems.push({
                  id: 202,
                  module: "factoring_application",
                  label: "Factoring Application",
                  icon: "",
                  link: "/factoring-application"
              })
            }
          }
          if (detectModule) {
              if (value=='letter_of_support'){
                detectModule.subItems.push({
                    id: 203,
                    module: "letter_of_support",
                    label: "Letter Of Support",
                    icon: "",
                    link: "/letter-of-support"
                });
              }
          }
          if (detectModule) {
              if (value=='term_sheet'){
                detectModule.subItems.push({
                    id: 204,
                    module: "term_sheet",
                    label: "Term Sheet",
                    icon: "",
                    link: "/term-sheet"
                });
              }
          }
        }
        else if (value=='asset_quality' || value=='legal_agreements' || value=='pre_disbursement' || value=='credit_review' || value=='asset_quality_reports'){
          const alreadyInsideArray = this.menuItems.find(e => e.module === 'assets_quality');
          if (alreadyInsideArray==undefined){
            this.menuItems.push(
              {
                id: 3,
                label: "Asset Quality",
                module: "assets_quality",
                icon: "uil uil-gold",
                subItems: []
            });
          }
          const detectModule = this.menuItems.find(e => e.module === 'assets_quality');
          if (detectModule) {
              if (value=='asset_quality'){
                detectModule.subItems.push({
                    id: 301,
                    module: "asset_quality",
                    label: "Asset Quality",
                    icon: "",
                    link: "/asset-quality"
                });
              }
          }
          if (detectModule) {
              if (value=='legal_agreements'){
                detectModule.subItems.push({
                  id: 302,
                  module: "legal_agreements",
                  label: "Legal Agreements",
                  icon: "",
                  link: "/legal-agreements"
              })
            }
          }
          if (detectModule) {
              if (value=='pre_disbursement'){
                detectModule.subItems.push({
                    id: 303,
                    module: "pre_disbursement",
                    label: "Pre Disbursement",
                    icon: "",
                    link: "/pre-disbursement"
                });
              }
          }
          if (detectModule) {
              if (value=='credit_review'){
                detectModule.subItems.push({
                    id: 304,
                    module: "credit_review",
                    label: "Credit Review",
                    icon: "",
                    link: "/credit-review"
                });
              }
          }
          if (detectModule) {
              if (value=='asset_quality_reports'){
                detectModule.subItems.push({
                    id: 305,
                    module: "asset_quality_reports",
                    label: "Reports",
                    icon: "",
                    link: "/asset-quality-reports"
                });
              }
          }
        }
        else if (value=='disbursement' || value=='refund' || value=='settlement' || value=='statement_of_account' || value=='notice_of_outstanding' || value=='operations_reports'){
          const alreadyInsideArray = this.menuItems.find(e => e.module === 'operations');
          if (alreadyInsideArray==undefined) {
            this.menuItems.push(
              {
                id: 4,
                label: "Operations",
                module: "operations",
                icon: "uil uil-files-landscapes-alt",
                subItems: []
            });
          }

          const detectModule = this.menuItems.find(e => e.module === 'operations');
          if (detectModule) {
              if (value=='disbursement'){
                detectModule.subItems.push({
                    id: 401,
                    module: "disbursement",
                    label: "Disbursement",
                    icon: "",
                    link: "/disbursement"
                });
              }
          }
          if (detectModule) {
              if (value=='refund'){
                detectModule.subItems.push({
                  id: 402,
                  module: "refund",
                  label: "Refund",
                  icon: "",
                  link: "/refund"
              })
            }
          }
          if (detectModule) {
              if (value=='settlement'){
                detectModule.subItems.push({
                    id: 403,
                    module: "settlement",
                    label: "Settlement",
                    icon: "",
                    link: "/settlement"
                });
              }
          }
          if (detectModule) {
              if (value=='statement_of_account'){
                detectModule.subItems.push({
                    id: 404,
                    module: "statement_of_account",
                    label: "Statement of Account",
                    icon: "",
                    link: "/statement-of-account"
                });
              }
          }
          if (detectModule) {
              if (value=='notice_of_outstanding'){
                detectModule.subItems.push({
                    id: 405,
                    module: "notice_of_outstanding",
                    label: "Notice of Outstanding",
                    icon: "",
                    link: "/notice-of-outstanding"
                });
              }
          }
          if (detectModule) {
              if (value=='operations_reports'){
                detectModule.subItems.push({
                    id: 406,
                    module: "operations_reports",
                    label: "Reports",
                    icon: "",
                    link: "/operations_reports"
                });
              }
          }
        }
        else if (value=='reports'){
          this.menuItems.push({
              id: 5,
              module: "report",
              label: "Report",
              icon: "uil uil-chart",
              link: "/reports"
          })
        }
        else if (value=='user_management'){
          this.menuItems.push({
              id: 6,
              module: "user_management",
              label: "User Management",
              icon: "uil uil-users-alt",
              link: "/user-management"
          })
        }
        else if (value=='approving_authority'){
          this.menuItems.push({
              id: 7,
              module: "approving_authority",
              label: "Approving Authority",
              icon: "uil uil-shield-check",
              link: "/Approving-authority"
          })
        }
       
      }
      )
      this.menuItems.push(
      {
        id: 99,
        label: "Settings",
        icon: "uil uil-setting",
        link: "/settings"
      })
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      const paths = [];

      for (var i = 0; i < links.length; i++) {
        paths.push(links[i]["pathname"]);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        matchingMenuItem = links[paths.indexOf(item)];
      } else {
        matchingMenuItem = links[itemIndex];
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      }
    },
    onRoutechange() {
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName(
            "mm-active"
          )[0].offsetTop;
          if (currentPosition > 500)
            this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
        }
      }, 300);
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <router-link to="/" class="logo logo-dark">
        <span class="logo-sm">
          <img src="@/assets/images/logo-sm.png" alt height="24" class="img-fluid" />
        </span>
        <span class="logo-lg text-center">
          <img src="@/assets/images/logo-light.png" alt height="50" class="" style="margin-left: -5px;" />
        </span>
      </router-link>

      <router-link to="/" class="logo logo-light">
        <span class="logo-sm ms-0">
          <img src="@/assets/images/logo-sm-light.png" alt height="40" />
        </span>
        <span class="logo-lg">
          <img src="@/assets/images/logo-light.png" alt height="58" />
        </span>
      </router-link>
    </div>

    <button
      type="button"
      @click="toggleMenu"
      class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
    >
      <i class="fa fa-fw fa-bars"></i>
    </button>

    <simplebar class="sidebar-menu-scroll" ref="currentMenu">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <template v-for="item in menuItems">
            <li class="menu-title" v-if="item.isTitle" :key="item.id">
             {{item.label}}
            </li>
            <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
              <a
                v-if="hasItems(item)"
                href="javascript:void(0);"
                class="is-parent"
                :class="{
                  'has-arrow': !item.badge,
                  'has-dropdown': item.badge,
                }"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>

                <span
                  :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                  v-if="item.badge"
                  >{{ $t(item.badge.text) }}</span
                >
                <span>{{ item.label }}</span>
              </a>

              <router-link
                :to="item.link"
                v-if="!hasItems(item)"
                class="side-nav-link-ref"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span>{{ item.label }}</span>
                <span
                  :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                  v-if="item.badge"
                  >{{ $t(item.badge.text) }}</span
                >
              </router-link>

              <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li v-for="(subitem, index) of item.subItems" :key="index">
                  <router-link
                    :to="subitem.link"
                    v-if="!hasItems(subitem)"
                    class="side-nav-link-ref"
                    >{{ subitem.label }}</router-link
                  >
                  <a
                    v-if="hasItems(subitem)"
                    class="side-nav-link-a-ref has-arrow"
                    href="javascript:void(0);"
                    >{{ subitem.label }}</a
                  >
                  <ul
                    v-if="hasItems(subitem)"
                    class="sub-menu mm-collapse"
                    aria-expanded="false"
                  >
                    <li
                      v-for="(subSubitem, index) of subitem.subItems"
                      :key="index"
                    >
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link-ref"
                        >{{ subSubitem.label }}</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
    <Common ref="commonFunc" />
  </div>
  <!-- Left Sidebar End -->
  
</template>